export const TITLE_SECTION_STYLES = {
  fontWeight: "normal",
  fontSize: "2.5em",
  lineHeight: "1.6em",
  textAlign: "center",
  color: "secondaryColorText",
  textTransform: "capitalize",
  fontFamily: "heading"
};

export const HEADING_STYLES = {
  color: 'mainColorText',
  fontSize:"28px",
  lineHeight: "1.2",
  textTransform: "capitalize",
  fontWeight:'500',
  fontFamily: "heading"
};

export const CONTENT_STYLES = {
  fontWeight: "normal",
  fontSize: "16px",
  color: "mainColorText",
};